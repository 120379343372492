<template>
  <div class="wrapper wrapper_in">
    <PanelHeader
      @toggleMenu="toggleMenu"
      :isOPen="isOPen"
      @close="close"
      @click="close"
    />
    <main class="main" :class="{ 'd-flex': user && user.ban_reason }">
      <template v-if="user && user.ban_reason">
        <UserBan :reason="user.ban_reason" />
      </template>
      <template v-else><slot /></template>
    </main>
    <MenuWrapper :isOPen="isOPen" :close="close" />
    <div class="menu-bg" :class="{ active: isOPen }" @click="close"></div>
  </div>
</template>


<script>
import apiClient from "@/services/AxiosService";
import MenuWrapper from "@/components/MenuWrapper";
import PanelHeader from "@/components/PanelHeader";
import { mapActions, mapGetters } from "vuex";
import { setLangHeader } from "@/services/AxiosService";
import "@/assets/css/vendor/bootstrap.min.css";
import "@/assets/css/main.scss";
import UserBan from "@/components/UserBan.vue";

export default {
  name: "PanelLayout",
  components: { PanelHeader, MenuWrapper, UserBan },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      selectBrowser: "statistics/getBrowsers",
      selectCountries: "statistics/getCountries",
      selectOS: "statistics/getOS",
      selectSubAccounts: "statistics/getSubAccounts",
    }),
  },
  data() {
    return {
      isOPen: false,
    };
  },
  mounted() {
    !this.user.id && this.setUser();
    !this.selectBrowser.length && this.setBrowsers();
    !this.selectCountries.length && this.setCountries();
    !this.selectOS.length && this.setOS();
    !this.selectSubAccounts.length && this.setSubAccounts();
  },
  watch: {
    "$i18n.locale": function () {
      setLangHeader(localStorage.getItem("lang"));
    },
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
      setCountries: "statistics/handleCountries",
      setBrowsers: "statistics/handleBrowsers",
      setOS: "statistics/handleOS",
      setSubAccounts: "statistics/handleSubAccounts",
    }),
    close() {
      if (!this.isOPen) {
        return;
      }
      this.isOPen = false;
    },
    toggleMenu() {
      this.isOPen = !this.isOPen;
    },
    changeLanguage() {
      const newLang = this.$i18n.locale == "ru" ? "en" : "ru";
      this.$i18n.locale = newLang;
      localStorage.setItem("lang", newLang);
    },
    async logOut() {
      await localStorage.setItem("authToken", "");
      await apiClient.get("user/logout");
      window.location.href = "/";
    },
  },
};
</script>
<style lang="scss" scoped>
.disable {
  pointer-events: none;
}
</style>

