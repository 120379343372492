import DashboardService from '@/api/dashboardService';

const state = () => ({
  user: {
    'id': null,
    'email': '',
    'name': '',
    'avatar': '',
    'balance': 0,
    'telegram': '',
    'skype': '',
    'news': 0,
    'tickets': 0,
    'rid': 0,
    'ref_code': '',
    'ban_reason': '',
    'confirm': true,
    'roles': [],
    'alert': null
  }
});

const getters = {
  getUser: (state) => {
    return state.user;
  }
};

const mutations = {
  setUserStore(state, payload) {
    if (payload) {
      state.user = payload;
    }
  }
};

const actions = {
  setUser({ commit }) {
    DashboardService.getUser().then((result) => {
      commit('setUserStore', result);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};