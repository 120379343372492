import AxiosService from "@/services/AxiosService";
class DashboardService {
  getTopCounties() {
    return AxiosService.get("/dashboard/top?limit=10")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getCPLRates() {
    return AxiosService.get("/cpl-rates")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getAllCounties() {
    return AxiosService.get("/dashboard/top")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getUser() {
    return await AxiosService.get(`/user`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new DashboardService();
