<template>
  <component :is="layout">
    <router-view />
  </component>

</template>
<script type="text/javascript" src="https://app.getbeamer.com/js/beamer-embed.js" defer="defer"></script>

<script>

export default {
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout';
    }
  },
    watch: {
    "$i18n.locale": function () {
      if (Beamer) {
        Beamer.update({
          ...beamer_config,
          language: localStorage.getItem("lang") === 'ru' ? 'ru' :'en',
        });
      }
    },
  },
};
</script>
