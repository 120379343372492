import AxiosService from "@/services/AxiosService";
class PanelService {
  getFaq() {
    return AxiosService.get("/help")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getNews() {
    return AxiosService.get("/news")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getFinancesHistory() {
    return AxiosService.get("/finances/history").then((response) => {
      return  response.data;
   }).catch(function (error) {
       console.log(error);
   });
  }
  withdrawFromWallet(payload) {
    return AxiosService.post("/finances/withdraw", payload).then((response) => {
      return  response.data;
   }).catch(function (error) {
       console.log(error);
   });
  }
  getReferals() {
    return AxiosService.get("/user/referrals")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getUserData() {
    return AxiosService.get("/user")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getWallets() {
    return AxiosService.get("/user/wallets")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  updateLanguage(lang) {
    return AxiosService.patch('/user/language', lang)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  async updateWallets(payload) {
    return await AxiosService.post("/user/wallets", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async updateUserData(payload) {
    return await AxiosService.post("/user/profile", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async updatePassword(payload) {
    return await AxiosService.post("/user/change-password", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new PanelService();
