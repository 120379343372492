<template>
  <header :class="{ 'open-menu': isOPen }" class="header">
    <div class="content content_in">
      <button class="header__menu" @click="toggle">
        <div :class="{ active: isOPen }" class="icon-menu">
          <div class="sw-topper"></div>
          <div class="sw-bottom"></div>
          <div class="sw-footer"></div>
        </div>
      </button>
      <a class="header__logo" href="/dashboard">
        <img :src="require('@/assets/images/logo.svg')" alt="" />
      </a>
      <div class="header__manager">
        <div class="manager">
          <div class="manager__item">
            <div class="manager__icon align-center">
              <svg
                v-svg
                role="presentation"
                size="0 0 20 18"
                symbol="telegram"
              ></svg>
            </div>
            <div class="manager__label">{{ $t("header.your_manager") }}</div>
            <a class="manager__link" href="https://t.me/Leo31PH" target="_blank"
              >@Leo31PH</a
            >
          </div>
          <div class="manager__chat">
            <div class="manager__chat-label">chat</div>
            <a
              class="manager__link"
              href="https://t.me/publisher_push_house"
              target="_blank"
              >@publisher_push_house</a
            >
          </div>
        </div>
      </div>
      <div class="header__lang language">
        <selectLanguage />
      </div>
      <div class="header__wallet">
        <div class="wallet">
          <div class="wallet__icon align-center">
            <svg v-svg role="presentation" size="0 0 20 18" symbol="usd"></svg>
          </div>
          <div class="wallet__label">{{ $t("header.balance") }}</div>
          <div class="wallet__value">{{ userBalance }}$</div>
        </div>
      </div>

      <div class="header__news" id="beamer-news">
        <img src="../assets/panel/images/speaker.svg" alt="" />
      </div>
      <div class="header__user">
        <div class="user">
          <a class="user__prev" @click.prevent="goToProfile">
            <profile-avatar />
          </a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import apiClient from "@/services/AxiosService";
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import "@/assets/css/header.scss";
import SelectLanguage from "@/components/SelectLanguage";
import ProfileAvatar from "@/components/ProfileAvatar";

export default {
  name: "PanelHeader",
  components: { SelectLanguage, ProfileAvatar },
  data() {
    return {
      balance: 0,
    };
  },
  props: {
    isOPen: {},
    close: {},
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    userBalance() {
      return this.user?.balance % 1 === 0
        ? this.user.balance?.toFixed(0)
        : this.user.balance?.toFixed(2);
    },
  },
  methods: {
    goToProfile() {
      if (this.user && !this.user.ban_reason) {
        this.$emit("close");
        if (this.$route.path !== "/profile") this.$router.push("/profile");
      }
    },
    numberFormat(str) {
      let num = parseInt(str, 10);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    toggle() {
      this.$emit("toggleMenu");
    },
    hide() {
      this.close();
    },
    logOut() {
      apiClient.get("/user/logout");
      localStorage.setItem("authToken", "");
      window.location.href = "/";
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss" scoped>
#beamer-news {
  margin-right: 40px;
  cursor: pointer;
  img {
    width: 22px;
    height: auto;
  }
  @media screen and (max-width: 920px) {
    margin-right: 0;
  }
}

.multiselect {
  &.select-language {
    display: none;
  }
}
</style>