<template>
  <div class="wrapper">
    <div class="entry">
      <div class="entry__body">
        <div class="entry-form">
          <div v-if="title" class="entry-form__title">{{ title }}</div>
          <div v-if="subtitle" class="entry-form__desc has-link">
            {{ subtitle }}
            <span class="sep-line"></span>
            <router-link :to="linkTo">{{ tileTo }}</router-link>
          </div>
          <slot />
        </div>
      </div>
      <div class="entry__support">
        <div class="entry-support">
          <div class="entry-support__head">
            <div class="entry-support__lang">
              <select-language simple />
            </div>
            <div class="entry-support__logo">
              <img :src="require('@/assets/images/entry/logo-white.svg')" alt="logo" height="64" width="185" />
            </div>
          </div>
          <div class="entry-support__title">{{ $t("auth.need_help") }}</div>
          <div class="entry-support__desc" v-html="$t('auth.send_message')"></div>
          <div class="entry-support__links">
            <a class="btn" href="https://t.me/Leo31PH" target="_blank">
              <svg v-svg role="presentation" size="0 0 20 18" symbol="telegram2"></svg>
              Leo31PH
            </a>
            <a class="btn" href="https://t.me/Robert_ph" target="_blank">
              <svg v-svg role="presentation" size="0 0 20 18" symbol="telegram2"></svg>
              Robert_ph
            </a>
            <a class="btn" href="skype:push.house.partner17:11?chat" target="_blank">
              <svg v-svg role="presentation" size="0 0 20 20" symbol="skype"></svg>
              Pushhouse
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectLanguage from "@/components/SelectLanguage.vue";
import { setLangHeader } from "@/services/AxiosService";
import "@/assets/css/vendor/bootstrap.min.css";
import "@/assets/css/main.scss";
import "@/assets/css/page-entry.scss";

export default {
  name: "AuthLayout",
  components: { SelectLanguage },
  computed: {
    title() {
      return this.$route.meta.pageTitle ? this.$t(`auth.${this.$route.meta.pageTitle}`) : "";
    },
    linkTo() {
      return this.$route.name === "Login" ? "/registration" : "/login";
    },
    tileTo() {
      return this.$route.name === "Login" ? this.$t(`auth.sign_up`) : this.$t(`auth.sign_in`);
    },
    subtitle() {
      return this.$route.name === "Registration" ? this.$t(`auth.have_account`) : this.$route.name === "Login" ? this.$t(`auth.have_not_account`) : "";
    },
  },
  watch: {
    "$i18n.locale": function () {
      setLangHeader(localStorage.getItem("lang"));
    },
  },
};
</script>