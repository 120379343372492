import axios from "axios";

const endpoint =
  process.env.VUE_APP_VIA_DEV_SERVER === "true"
    ? "/api/v0/"
    : process.env.VUE_APP_API_DOMAIN + "/api/v0/";

export const apiClient = axios.create({
  baseURL: endpoint,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    const originalRequest = response.config;
    if (response.status === 201) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      return apiClient
        .post("refresh-token", {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (
            res.status === 200 &&
            res.data.status === 200 &&
            res.data.data.token
          ) {
            localStorage.setItem("authToken", res.data.data.token);
            apiClient.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("authToken");
            return apiClient(originalRequest);
          } else {
            localStorage.setItem("authToken", "");
            localStorage.setItem("refreshToken", "");
            window.location.href = "/";
          }
        });
    } else {
      return response;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const setLangHeader = (lang) => {
  apiClient.defaults.headers.common["UserLanguage"] = lang ? lang : "en";
};

setLangHeader(localStorage.getItem("lang"));
export default apiClient;
